import React from 'react'
import './Card.scss'

interface IPROPS {
  title: string
  children: React.ReactNode
}

export default function Card (props: IPROPS): React.ReactElement {
  return (
    <div className='Card'>
      <h1 className="Card__title">{props.title}</h1>
      <div>
        {props.children}
      </div>
    </div>
  )
}
