import { ID_TOKEN_KEY } from "./auth";
import jwtdecode from "jwt-decode";

export function downloadFile(href: string, name: string) {
  const link = document.createElement("a");
  link.href = href;
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const isEmail = (value: string) =>
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
    value
  );

export const formatNumber = (
  price: number,
  options?: Intl.NumberFormatOptions
) => {
  return new Intl.NumberFormat("en", {
    ...(options
      ? {
          ...options,
          style: options.currency ? "currency" : options.style,
        }
      : {}),
  }).format(price);
};

export function lockScroll(isLocked: boolean) {
  document.querySelector("html")!.style.overflow = isLocked ? "hidden" : "";
}

export const isPasswordValid = (value: string) =>
  containsCapital(value) &&
  containsNonCapital(value) &&
  containsDigit(value) &&
  containsSpecialCharacter(value) &&
  noSpacesOnEnds(value) &&
  isPasswordLongEnough(value);

export function noSpacesOnEnds(v: string) {
  return v === v.trim();
}

export function containsCapital(v: string) {
  return /[A-Z]/.test(v);
}
export function containsNonCapital(v: string) {
  return /[a-z]/.test(v);
}
export function containsDigit(v: string) {
  return /\d/.test(v);
}
export function containsSpecialCharacter(v: string) {
  const characters = [
    "^",
    "$",
    "*",
    ".",
    "[",
    "]",
    "{",
    "}",
    "(",
    ")",
    "?",
    '"',
    "!",
    "@",
    "#",
    "%",
    "&",
    "/",
    "\\",
    ",",
    ">",
    "<",
    "'",
    ":",
    ";",
    "|",
    "_",
    "~",
    "`",
    "=",
    "+",
    "-",
  ];

  return !!v.split("").filter((c) => characters.includes(c)).length;
}
export function isPasswordLongEnough(password: string) {
  return password.length >= 8;
}

export const getEmailFromToken = (): string => {
  const decodedToken: { email: string } = jwtdecode(
    localStorage.getItem(ID_TOKEN_KEY)!
  );
  const { email } = decodedToken;

  return email;
};
