import './Loader.scss';
import styled from 'styled-components';

export default function Loader() {
  return (
    <Overlay className='d-flex align-items-center justify-content-center'>
      <div className='Loader'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Overlay>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(10, 5, 39, 0.3);
  z-index: 1020;
`;
