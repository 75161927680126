import React from 'react'
import Card from '../../components/Card/Card'
import './other.scss'

export default function TermsConditions (): React.ReactElement {
  return (
    <div className='container py-4 py-lg-5'>
      <Card title="LedeAI Terms of Service">
      Last Updated: 8 November 2023 <br />
        <div className="privacyContainer" >
          <ol className="m-0">
            <li>Acceptance of Terms</li>
            <div className="ml--5" >
                  Lede AI LLC ("Company"), with a registered office at 40 W 4th St, Mansfield, Ohio 44902, provides its Software as a Service (SaaS) subject to these Terms of Service ("Terms"). By accessing or using our SaaS, you agree to be bound by these Terms.
            </div>

            <li>Modification of Terms</li>
            <div>
            The Company reserves the right, at its discretion, to modify these Terms at any time. Continued use of the SaaS after such modifications constitutes acceptance of the revised Terms.
            </div>
            <li>Service Description</li>
            <div>
              The Company provides a SaaS platform that enables users to upload, edit, and manage data. Features include data performance tracking and other related services.
            </div>
            <li>Data Collection</li>
            <div>
              The Company will collect performance data including but not limited to the data uploaded, edited, and interactions within the SaaS platform ("Performance Data") for the purpose of enhancing service delivery.
            </div>
            <li>User Obligations</li>
            <div>
              Users agree to not misuse the service and to comply with all applicable laws and regulations while using the SaaS. Users are responsible for all activities under their account.
            </div>
            <li>Intellectual Property</li>
            <div>
            The Company owns all rights, title, and interest in the SaaS, including all related intellectual property rights. No rights are granted to the user other than as expressly set forth in these Terms.
            </div>
            <li>Privacy</li>
            <div>
              User data will be handled in accordance with the Company’s Privacy Policy, which outlines the collection, use, and protection of personal information.
            </div>
            <li>Warranties and Disclaimers</li>
            <div>
            The SaaS is provided "as is" without warranties of any kind. The Company does not warrant that the SaaS will meet all user requirements or that operation will be uninterrupted or error-free.
            </div>
            <li>Limitation of Liability</li>
            <div>
            To the fullest extent permitted by law, the Company shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.
            </div>
            <li>Indemnification</li>
            <div>
            Users agree to indemnify and hold harmless the Company and its officers, directors, employees, from any claims arising from or related to the use of the SaaS.
            </div>
            <li>Termination</li>
            <div>
            The Company may terminate or suspend access to the SaaS immediately, without prior notice or liability, for any reason, including breach of these Terms.
            </div>
            <li>Governing Law</li>
            <div>
             These Terms are governed by the laws of the State of Ohio without regard to its conflict of laws principles.
            </div>
            <li>Contact Information</li>
            <div>
            For any questions about these Terms, please contact the Company at the address provided.<br />
            By using the SaaS provided by Lede AI LLC, you acknowledge that you have read these Terms, understand them, and agree to be bound by them.
            </div>
          </ol>
        </div>
      </Card>
    </div>
  )
}
