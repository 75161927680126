import classNames from 'classnames';
import './Input.scss';
import { ChangeEventHandler, FocusEventHandler, EventHandler} from 'react';
import { useState } from 'react';
import { ReactComponent as EyeOn } from "../../icons/eyeon.svg"
import { ReactComponent as EyeOff } from "../../icons/eyeoff.svg"

import './Input.scss';
interface Props {
  value: string;
  type?: string;
  onChange: ChangeEventHandler;
  externalPwd?: {
    value: string;
    setValue: (v: string ) => void;
  };
  name: string;
  label?: string;
  className?: string;
  required?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  error?: boolean | string | undefined;
  onBlur?: FocusEventHandler;
  style?: object;
}


export default function PasswordInput({
  value,
  type = 'password',
  onChange,
  externalPwd,
  onBlur,
  name,
  label,
  className = ' ',
  required = false,
  readOnly = false,
  placeholder = '',
  error,
  style = {},
}: Props) {


  const [eyeState, seteyeState] = useState(true)

  const eyeClick = () => {

    if (eyeState) {
        externalPwd?.setValue("password")
    }else {
        externalPwd?.setValue("text")
    }

    seteyeState(!eyeState)
  }

  return (
    <div className={`Input ${className}`} style={style}>
      {!!label && <div className='Input__label fw-bold mb-2'>{label}</div>}
      <div className='d-flex'>
        <div className='flex-grow-1'>
          <input
            value={value}
            onChange={onChange}
            type={externalPwd?.value}
            className={classNames('Input__input w-100', {
              'Input__input--error': error,
            })}
            name={name}
            onBlur={onBlur}
            readOnly={readOnly}
            placeholder={placeholder}
          />
          { eyeState && <EyeOff className="svg-image" onClick={eyeClick} /> }
          { !eyeState && <EyeOn className='svg-image' onClick={eyeClick} />}

        </div>
        {required && (
          <div
            style={{
              paddingLeft: 9,
              color: !!error ? '#ee465a' : undefined,
            }}
          >
            *
          </div>
        )}
      </div>
      {error && <div className='Input__error text-end'>{error}</div>}
    </div>
  );
}
