import { useState } from 'react';
import { lockScroll } from '../core/helpers';

export const useModal = (initialOpened = false) => {
  const [isModalOpened, setIsModalOpened] = useState(initialOpened);

  const closeModal = () => {
    setIsModalOpened(false);
    lockScroll(false);
  };

  const openModal = () => {
    setIsModalOpened(true);
    lockScroll(true);
  };

  return {
    isModalOpened,
    closeModal,
    openModal,
  };
};
