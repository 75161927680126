import { ReactComponent as Logo } from '../icons/logo.svg';
import { PropsWithChildren } from 'react';

export default function LoginContainer({ children }: PropsWithChildren) {
  return (
    <div className='Login flex-grow-1 d-flex align-items-center flex-column'>
      <Logo />
      {children}
    </div>
  );
}
